<template>
  <div class="app-sidebar">
    <div>
      <el-menu
        class="el-menu-vertical-demo"
        :default-active="defaultActive"
        background-color="#20222a"
        text-color="#fff"
        active-text-color="#ffd04b">
        <template v-for="item in sideBarList">
          <el-submenu v-if="item.children" :key="item.name" :index="item.name">
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>
            <!-- 二级菜单 -->
            <template v-for="subItem in item.children">
              <el-submenu v-if="subItem.children" :key="subItem.name" :index="subItem.name">
                <template slot="title">
                  <span>{{ subItem.title }}</span>
                </template>
                <!-- 三级菜单 -->
                <router-link
                  v-for="grandchildItem in subItem.children"
                  :key="grandchildItem.name"
                  :to="grandchildItem.path">
                  <el-menu-item :index="grandchildItem.name">
                    {{ grandchildItem.title }}
                  </el-menu-item>
                </router-link>
              </el-submenu>
              <!-- 二级else -->
              <router-link :to="subItem.path" :key="subItem.name" v-else>
                <el-menu-item :key="subItem.name" :index="subItem.name">
                  {{ subItem.title }}
                </el-menu-item>
              </router-link>
            </template>
          </el-submenu>
          <!-- 一级else -->
          <router-link :to="item.path" :key="item.name" v-else>
            <el-menu-item :index="item.name">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </router-link>
        </template>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SideBar',
  data() {
    return {
    }
  },
  computed: {
    // 根据当前路由设置激活侧边栏
    defaultActive() {
      return this.stageInfo.name
    },
    stageInfo() {
      return this.$store.getters.getStageInfo(this.$route.name)
    },
    ...mapGetters(['sideBarList'])
  }
}
</script>

<style lang="scss" scoped>
.app-sidebar {
  background: rgb(48, 65, 86);
}

.el-menu-vertical-demo {
  border: none;
}

</style>
