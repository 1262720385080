<template>
  <div style="height:100%;">
    <el-container>
      <el-aside :width="sideBarWidth" class="aside">
        <div class="logo-wrapper">
          <img class="logo-img" src="./logo.png" />
        </div>
        <side-bar></side-bar>
      </el-aside>
      <el-container>
        <el-main ref="main">
          <app-main ref="appMain"></app-main>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import SideBar from '@/components/layout/side-bar'
import AppMain from '@/components/layout/app-main'

const sideBarWidth = '210px'

export default {
  name: 'Home',
  data() {
    return {
      sideBarWidth // 左侧菜单栏展开的宽度
    }
  },
  mounted() {
    this.setResize()
    window.onresize = () => {
      this.setResize()
    }
  },
  methods: {
    // 响应页面的宽度高度变化
    setResize() {
      this.clientHeight = document.body.clientHeight
      this.clientWidth = document.body.clientWidth
      console.log(this.clientHeight)
      this.$refs.appMain.$el.style.height = `${this.clientHeight}px`
    }
  },
  components: {
    SideBar,
    AppMain
  }
}
</script>

<style lang="scss" scoped>
.aside {
  background-color: #20222a;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.el-main {
  overflow-y: auto;
  position: relative;
  padding: 0;
}

.logo-wrapper {
  width: 210px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  .logo-img {
    width: 120px;
  }
}
</style>
